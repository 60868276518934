<template>
  <button
    class="member-option"
    :class="{
      'member-option--with-fixed-width': hasFixedWidth,
      'member-option--clickable': isClickable
    }"
    :title="member.label"
  >
    <i :class="`member-option__icon icon-${getMemberFormatIcon(member)}`" />
    <label class="member-option__label">
      {{ member.label }}
    </label>
    <div class="member-option__help-button">
      <AnalyticsMemberSelectionDefinition
        :member="member"
        :member-type="memberType"
      />
    </div>
  </button>
</template>

<script lang="ts">
import { getMemberFormatIcon } from '~/services/explore'
import {
  MemberType,
  type DimensionOption,
  type MeasureOption
} from '~/types/analytics'

export default {
  name: 'MemberOption',
  props: {
    member: {
      type: Object as PropType<DimensionOption | MeasureOption>,
      required: true
    },
    memberType: {
      type: String as PropType<MemberType>,
      required: true
    },
    hasFixedWidth: {
      type: Boolean,
      default: true
    },
    isClickable: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      getMemberFormatIcon
    }
  }
}
</script>

<style lang="scss" scoped>
.member-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $margin-intermediate;
  height: 48px;
  column-gap: $margin-tiny;
  overflow: hidden;
  width: 100%;

  &--with-fixed-width {
    width: 280px;
  }

  &__icon {
    @include font-icon;
    @include font-size($font-size-intermediate);
    color: $text-secondary;
    cursor: inherit;
  }

  &__label {
    flex: 1;
    @include font-text($field-font-weight);
    @include font-size($font-size-regular);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $text-secondary;
    cursor: inherit;
  }

  &__help-button {
    display: none;
  }

  &:hover {
    .member-option__help-button {
      display: flex;
    }
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $button-primary;

      .member-option__label,
      .member-option__icon {
        color: $text-inverse;
      }
    }
  }
}
</style>
